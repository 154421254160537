import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation } from "@reach/router";
import ClassNames from "classnames";
import Layout from "components/layout";
import Style from "styles/contact.module.scss";
import SEO from "components/seo";
import { contact_type_dict, contact_type_email_map, domain } from "../../constants";

const textMaxLength = 500;

const Contact = () => {
  let contactTypeDefaultValue = "";
  const { register, trigger, errors, getValues, watch, formState } = useForm({
    defaultValues: { contact_type: contactTypeDefaultValue },
    mode: "all",
  });

  const [count, setCount] = useState(0);
  const [submitDisabled, setSubmitDisabled] = useState(true);

  const confirmButtonClass = ClassNames({
    [Style.submitBox__confButton]: true,
    [Style.submitBox__confButton__valid]: !submitDisabled,
  });

  const CONTACT_SUBJECT =
    "\u3010\u682a\u5f0f\u4f1a\u793e\u30a4\u30fc\u30bc\u3011\u304a\u554f\u3044\u5408\u308f\u305b\u3042\u308a\u304c\u3068\u3046\u3054\u3056\u3044\u307e\u3059";

  const CONTACT_BODY =
    "\u25a0\u304a\u554f\u3044\u5408\u308f\u305b\u9805\u76ee\n{{contact_type}}\n\u25a0\u540d\u524d\n{{name}}\n\u25a0\u30d5\u30ea\u30ac\u30ca\n{{kana}}\n\u25a0\u30e1\u30fc\u30eb\u30a2\u30c9\u30ec\u30b9\n{{email}}\n\u25a0\u96fb\u8a71\u756a\u53f7\n{{phone}}\n\u25a0\u4f1a\u793e\u540d\u30fb\u5b66\u6821\u540d\n{{group}}\n\u25a0\u304a\u554f\u3044\u5408\u308f\u305b\u5185\u5bb9\n{{content}}";

  const getContactBody = () => {
    return CONTACT_BODY.replace("{{contact_type}}", contact_type_dict[getValues("contact_type")])
      .replace("{{name}}", getValues("name"))
      .replace("{{kana}}", getValues("kana"))
      .replace("{{email}}", getValues("email"))
      .replace("{{phone}}", getValues("phone"))
      .replace("{{group}}", getValues("group"))
      .replace("{{content}}", getValues("content"));
  };

  let contactType = watch("contact_type");
  const getAddress = () => {
    return contact_type_email_map[contact_type_dict[contactType]];
  };

  const location = useLocation();
  const prevPath = location.state ? location.state.prevPath : null;
  // 遷移元によってデフォルトのお問い合わせ項目を決定する
  switch (prevPath) {
    case "/comachicart/func/":
      contactTypeDefaultValue = "comachicart";
    default:
      break;
  }

  const handleAgreementChange = e => {
    const target = e.target;
    const value = target.checked;
    setSubmitDisabled(!value);
  };

  return (
    <Layout>
      <SEO title="Contact" path="contact" />
      <div className={Style.container}>
        <div className={Style.ctIntro}>
          <div className={Style.ctIntro__inner}>
            <h1 className={Style.ctIntro__title}>CONTACT</h1>
          </div>
        </div>
        <section className={Style.ctMessage}>
          <p className={Style.ctMessage__txt}>
            お問い合わせは、下記フォームよりお気軽にご連絡ください。
            <br />
            お問い合わせ内容の確認後、担当者よりご連絡させていただきます。
          </p>
        </section>
        <form className={Style.ctForm}>
          <table className={Style.ctFormTable}>
            <tbody>
              <tr>
                <th>
                  <label htmlFor="contact_type">
                    お問い合わせ項目<span className={Style.ctFormTable__required}>*</span>
                  </label>
                </th>
                <td>
                  <select name="contact_type" ref={register({ required: true })}>
                    <option value="" hidden>
                      選択して下さい
                    </option>
                    <option value="partner">ビジネスパートナーに関して</option>
                    <option value="comachicart">comachicartに関して</option>
                    <option value="recruit">求人に関して</option>
                    <option value="other">その他</option>
                  </select>
                  {errors.contact_type && (
                    <p role="alert" className={Style.ctFormTable__error}>
                      お問い合わせ項目を選択してください
                    </p>
                  )}
                </td>
              </tr>

              <tr>
                <th>
                  <label htmlFor="name">
                    名前<span className={Style.ctFormTable__required}>*</span>
                  </label>
                </th>
                <td>
                  <input name="name" ref={register({ required: true })} />
                  {errors.name && (
                    <p role="alert" className={Style.ctFormTable__error}>
                      名前を入力して下さい
                    </p>
                  )}
                </td>
              </tr>

              <tr>
                <th>
                  <label htmlFor="kana">
                    フリガナ<span className={Style.ctFormTable__required}>*</span>
                  </label>
                </th>
                <td>
                  <input name="kana" ref={register({ required: true })} />
                  {errors.kana && (
                    <p role="alert" className={Style.ctFormTable__error}>
                      フリガナを入力して下さい
                    </p>
                  )}
                </td>
              </tr>

              <tr>
                <th>
                  <label htmlFor="email">
                    メールアドレス<span className={Style.ctFormTable__required}>*</span>
                  </label>
                </th>
                <td>
                  <input
                    type="email"
                    name="email"
                    ref={register({ required: true, value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i })}
                  />
                  {errors.email && (
                    <p role="alert" className={Style.ctFormTable__error}>
                      メールアドレスを正しく入力して下さい
                    </p>
                  )}
                </td>
              </tr>

              <tr>
                <th>
                  <label htmlFor="phone">電話番号</label>
                </th>
                <td>
                  <input name="phone" ref={register({ required: false })} />
                  {errors.phone && (
                    <p role="alert" className={Style.ctFormTable__error}>
                      電話番号を正しく入力して下さい
                    </p>
                  )}
                </td>
              </tr>

              {/* <tr>
                <th>
                  <label htmlFor="zip_code">住所</label>
                </th>
                <td>
                  <input name="zip_code" ref={register({ required: false })} />
                  <input name="address1" placeholder="都道府県 市区町村・番地" ref={register({ required: false })} />
                  <input name="address2" placeholder="マンション・ビル名" ref={register({ required: false })} />
                  {errors.zipCode && <p className="formError">住所を正しく入力して下さい</p>}
                </td>
              </tr> */}

              <tr>
                <th>
                  <label htmlFor="group">会社名・学校名</label>
                </th>
                <td>
                  <input name="group" ref={register({ required: false })} />
                </td>
              </tr>

              <tr>
                <th>
                  <label htmlFor="content">
                    お問い合わせ内容<span className={Style.ctFormTable__required}>*</span>
                  </label>
                </th>
                <td>
                  <textarea
                    cols="50"
                    rows="10"
                    name="content"
                    maxLength={textMaxLength}
                    ref={register({
                      required: true,
                      rows: 10,
                      minLength: 1,
                      maxLength: textMaxLength,
                    })}
                    onChange={e => setCount(e.target.value.length)}
                  />
                  <p className={Style.ctFormTable__count}>
                    <span>
                      {count}/{textMaxLength}
                    </span>
                  </p>
                  {errors.content && (
                    <p role="alert" className={Style.ctFormTable__error}>
                      お問い合わせ内容を{textMaxLength}文字以内で入力して下さい
                    </p>
                  )}
                </td>
              </tr>
            </tbody>
          </table>
          <div className={Style.agreementBox}>
            <label htmlFor="agree">
              <input
                type="checkbox"
                name="agree"
                id="agree"
                value="プライバシーポリシーに同意する"
                onChange={handleAgreementChange}
              />
              <span>
                <a href="/privacy/" target="_blank" rel="noopener noreferrer">
                  プライバシーポリシー
                </a>
                に同意する
              </span>
            </label>
          </div>
          <div className={Style.submitBox}>
            {!formState.isValid || submitDisabled ? (
              <a id="submit" className={confirmButtonClass} onClick={() => trigger()}>
                メーラーを開く
              </a>
            ) : (
              <a
                id="submit"
                href="#mailgo"
                data-address={getAddress()}
                data-domain={domain}
                data-subject={CONTACT_SUBJECT}
                data-body={getContactBody()}
                className={confirmButtonClass}
                onClick={() => trigger()}
              >
                メーラーを開く
              </a>
            )}
          </div>
        </form>
      </div>
    </Layout>
  );
};

export default Contact;
